import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { logOut } from "../auth/authentication"

export default function LogOut() {
    const navigate = useNavigate()

    const onLogOut = async () => {
        alert(await logOut())
        navigate("/")
        window.location.reload()
    }

    useEffect(() => {
        onLogOut()
    }, [])

    return <></>
}
