import { fetchUsers } from "../api/usersApiManager"
import {
    clearCurrentSession,
    setCurrentSession
} from "../redux/slices/currentSessionSlice"
import { addUser } from "../redux/slices/usersSlice"
import { store } from "../redux/store"

export async function login(id, password) {
    await fetchUsers()

    const matchingUsers = store
        .getState()
        .users.value.filter((u) => u.id === id)
    const doesIdExist = matchingUsers.length > 0

    const LOGIN_SUCCESS_MESSAGE = "Logged in"
    const LOGIN_FAIL_MESSAGE = "Login failed"

    if (doesIdExist) {
        const user = matchingUsers[0]
        let hashedUserPassword = ""
        switch (user.passwordHashVersion) {
            case "v1":
                hashedUserPassword = HashPassword.v1(password)
                break
            default:
                hashedUserPassword = HashPassword.v1(password)
                break
        }

        if (hashedUserPassword === user.password) {
            // Login success
            store.dispatch(setCurrentSession(user))
            return LOGIN_SUCCESS_MESSAGE
        }
    }

    return LOGIN_FAIL_MESSAGE
}

const ACCEPT_NEW_MEMBERS = false

export async function register(id, password) {
    const REGISTER_FAIL_DISABLED_MESSAGE = "Register is disabled for now"

    if (ACCEPT_NEW_MEMBERS) {
        await fetchUsers()

        const matchingUsers = store
            .getState()
            .users.value.filter((u) => u.id === id)
        const isIdTaken = matchingUsers.length > 0

        const REGISTER_SUCCESS_MESSAGE = "Registered"

        const REGISTER_FAIL_ID_ALREADY_TAKEN_MESSAGE = "ID already taken"
        const REGISTER_FAIL_ID_INVALID_MESSAGE = "Invalid ID"
        const REGISTER_FAIL_PASSWORD_INVALID_MESSAGE = "Invalid password"
        const REGISTER_FAIL_ID_AND_PASSWORD_INVALID_MESSAGE =
            "Invalid ID and password"

        if (!isIdTaken) {
            if (id === "" && password === "") {
                return REGISTER_FAIL_ID_AND_PASSWORD_INVALID_MESSAGE
            } else if (id === "") {
                return REGISTER_FAIL_ID_INVALID_MESSAGE
            } else if (password === "") {
                return REGISTER_FAIL_PASSWORD_INVALID_MESSAGE
            } else {
                store.dispatch(
                    addUser({
                        id: id,
                        password: HashPassword.v1(password),
                        passwordHashVersion: "v1",
                        isAdmin: false
                    })
                )
                return REGISTER_SUCCESS_MESSAGE
            }
        } else {
            return REGISTER_FAIL_ID_ALREADY_TAKEN_MESSAGE
        }
    } else {
        return REGISTER_FAIL_DISABLED_MESSAGE
    }
}

export async function logOut() {
    const LOG_OUT_SUCCESS_MESSAGE = "Logged out"
    const LOG_OUT_FAIL_MESSAGE = "Log out failed"

    if (store.getState().currentSession.value !== "") {
        store.dispatch(clearCurrentSession())
        return LOG_OUT_SUCCESS_MESSAGE
    }

    return LOG_OUT_FAIL_MESSAGE
}

export class HashPassword {
    static v1(password) {
        let hash = 0
        for (let i = 0; i < password.length; i++) {
            let chr = password.charCodeAt(i)
            hash = (hash << 5) - hash + chr
            hash |= 0
        }

        return hash
    }
}
