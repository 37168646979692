import axios from "axios"
import { clearUsers, setUsers } from "../redux/slices/usersSlice"
import { store } from "../redux/store"

const SERVER_URL = process.env.REACT_APP_JSON_SERVER_URL

export const fetchUsers = async () => {
    const response = await axios.get(SERVER_URL + "/users").catch((e) => {
        getUsersErrorHandler(e)
        store.dispatch(clearUsers())
        return
    })

    store.dispatch(setUsers(response.data))
}

const getUsersErrorHandler = (e) => {
    console.log(e.message)
}

export const postUser = async (user) => {
    await axios.post(SERVER_URL + "/users/", user).catch((e) => {
        postUserErrorHandler(e)
        return
    })
}

const postUserErrorHandler = (e) => {
    console.log(e.message)
}
