import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { fetchPostsByCreatedAtDescending } from "../api/postsApiManager"
import {
    PageWrapDiv,
    PageWrapDivInner
} from "../components/shared/LayoutComponents"
import Post from "../components/shared/Post"

export default function ViewPost() {
    const allPosts = useSelector((state) => state.posts.value)
    const [post, setPost] = useState({ id: "" })
    const postId = useParams().id

    const fetchAndFilterPost = async () => {
        if (post.id !== "") {
            return
        }

        await fetchPostsByCreatedAtDescending()

        const filteredPosts = allPosts.filter((post) => post.id === postId)
        if (filteredPosts.length > 0) {
            setPost(filteredPosts[0])
        } else {
            setPost({ id: "" })
        }
    }

    useEffect(() => {
        fetchAndFilterPost()
    }, [post])

    return (
        <PageWrapDiv>
            <PageWrapDivInner>{post && <Post post={post} />}</PageWrapDivInner>
        </PageWrapDiv>
    )
}
