import axios from "axios"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

export default function Post({ post, displayMode = "page" }) {
    const loggedInUser = useSelector((state) => state.currentSession.value)

    const isOwnPost = () => {
        return post.authorId === loggedInUser.id
    }

    const [newTitle, setNewTitle] = useState("")
    const [newContent, setNewContent] = useState("")

    const [isEditingPost, setIsEditingPost] = useState(false)

    const navigate = useNavigate()

    const onEditPost = async () => {
        if (isEditingPost) {
            const newPost = {
                ...post,
                title: newTitle,
                content: newContent
            }

            await axios.patch(
                process.env.REACT_APP_JSON_SERVER_URL + "/posts/" + post.id,
                newPost
            )

            window.location.reload()
        } else {
            setNewTitle(post.title)
            setNewContent(post.content)
        }

        setIsEditingPost(!isEditingPost)
    }

    const onDeletePost = async () => {
        await axios.delete(
            process.env.REACT_APP_JSON_SERVER_URL + "/posts/" + post.id
        )

        navigate("/")
    }

    if (post.id === "") {
        return <>Post not found</>
    }

    switch (displayMode) {
        case "newsfeed":
            return (
                <StyledNewsfeedPostDiv
                    onClick={() => {
                        navigate("/viewpost/" + post.id)
                    }}
                >
                    <h3>{post.title}</h3>
                    <p>at {new Date(post.createdAt).toDateString()}</p>
                    <p>
                        {post.content.substr(0, 100)}
                        {post.content.length > 100 && "..."}
                    </p>
                </StyledNewsfeedPostDiv>
            )
        case "page":
            return (
                <>
                    <h2>{post.title}</h2>
                    <p>at {new Date(post.createdAt).toDateString()}</p>
                    <p style={{ whiteSpace: "pre-wrap" }}>{post.content}</p>
                    {isOwnPost() && (
                        <>
                            <button onClick={onEditPost}>수정</button>
                            <button onClick={onDeletePost}>삭제</button>
                        </>
                    )}
                    {isEditingPost && (
                        <>
                            <input
                                value={newTitle}
                                onChange={(e) => {
                                    setNewTitle(e.target.value)
                                }}
                            />
                            <textarea
                                value={newContent}
                                onChange={(e) => {
                                    setNewContent(e.target.value)
                                }}
                            />
                        </>
                    )}
                </>
            )
        default:
            return <></>
    }
}

const StyledNewsfeedPostDiv = styled.div`
    cursor: pointer;
`
