import axios from "axios"
import { clearPosts, setPosts } from "../redux/slices/postsSlice"
import { store } from "../redux/store"

const SERVER_URL = process.env.REACT_APP_JSON_SERVER_URL

export const fetchPostsByCreatedAtDescending = async () => {
    const response = await axios
        .get(SERVER_URL + "/posts?_sort=createdAt&_order=desc")
        .catch((e) => {
            getPostsErrorHandler(e)
            store.dispatch(clearPosts())
            return
        })

    store.dispatch(setPosts(response.data))
}

const getPostsErrorHandler = (e) => {
    console.log(e.message)
}

export const postPost = async (payload) => {
    await axios.post(SERVER_URL + "/posts/", payload).catch((e) => {
        postPostErrorHandler(e)
        return
    })
}

const postPostErrorHandler = (e) => {
    console.log(e.message)
}
