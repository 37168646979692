import styled from "styled-components"
import {
    PageWrapDiv,
    PageWrapDivInner
} from "../components/shared/LayoutComponents"
import Newsfeed from "../components/shared/Newsfeed"

export default function Home() {
    return (
        <PageWrapDiv>
            <PageWrapDivInner>
                <LabeledNewsfeed label="WIL" category="WIL" />
                <LabeledNewsfeed label="TIL" category="TIL" />
                <LabeledNewsfeed label="General" category="General" />
                <LabeledNewsfeed label="All" />
            </PageWrapDivInner>
        </PageWrapDiv>
    )
}

const LabeledNewsfeed = ({ label, category = "all", authorId = "" }) => {
    return (
        <>
            <LabeledNewsfeedDiv>
                <LabeledNewsfeedLabel>
                    <h3>{label}</h3>
                    <hr />
                </LabeledNewsfeedLabel>
                <Newsfeed category={category} authorId={authorId} />
            </LabeledNewsfeedDiv>
        </>
    )
}

const LabeledNewsfeedDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > div {
        width: 100%;
        margin: auto;
    }
`

const LabeledNewsfeedLabel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;

    & > * {
        width: 100%;
    }
`
