import { useEffect, useState } from "react"
import { PageWrapDiv } from "../components/shared/LayoutComponents"
import {
    LabeledInput,
    LabeledSelect,
    LabeledTextArea,
    PrimaryButton,
    StyledForm
} from "../components/shared/SharedComponents"
import { v4 as uuidv4 } from "uuid"
import { useDispatch, useSelector } from "react-redux"
import { addPost } from "../redux/slices/postsSlice"
import { useNavigate } from "react-router-dom"

export default function WritePost() {
    const [newTitle, setNewTitle] = useState("")
    const [newCategory, setNewCategory] = useState("General")
    const [newContent, setNewContent] = useState("")
    const user = useSelector((state) => state.currentSession.value)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onWritePostFormSubmit = (e) => {
        e.preventDefault()

        const isLoggedIn = user && user.id !== ""
        if (isLoggedIn) {
            const newPost = {
                id: uuidv4(),
                createdAt: Date.now(),
                title: newTitle,
                category: newCategory,
                content: newContent,
                authorId: user.id
            }

            dispatch(addPost(newPost))
            navigate("/viewpost/" + newPost.id)
        }
    }

    useEffect(() => {
        const isLoggedIn = user && user.id !== ""
        if (!isLoggedIn) {
            navigate("/login")
        }
    })

    return (
        <PageWrapDiv>
            <StyledForm onSubmit={onWritePostFormSubmit}>
                <LabeledInput
                    labelValue="Title"
                    placeholder="Enter title"
                    value={newTitle}
                    onChange={(e) => {
                        setNewTitle(e.target.value)
                    }}
                />
                <LabeledSelect
                    labelValue="Category"
                    options={["General", "TIL", "WIL"]}
                    value={newCategory}
                    onChange={(e) => {
                        setNewCategory(e.target.value)
                    }}
                />
                <LabeledTextArea
                    labelValue="Content"
                    placeholder="Enter content"
                    value={newContent}
                    onChange={(e) => {
                        setNewContent(e.target.value)
                    }}
                />
                <PrimaryButton type="submit">Post</PrimaryButton>
            </StyledForm>
        </PageWrapDiv>
    )
}
