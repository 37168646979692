import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { fetchPostsByCreatedAtDescending } from "../../api/postsApiManager"
import Post from "./Post"

export default function Newsfeed({ category = "all", authorId = "" }) {
    const allPosts = useSelector((state) => state.posts.value)
    const [filteredPosts, setFilteredPosts] = useState(allPosts)

    const fetchAndFilterPosts = async () => {
        if (filteredPosts.length > 0) {
            return
        }

        await fetchPostsByCreatedAtDescending()

        const filterByCategory = category !== "all"
        const filterByUser = authorId !== ""

        if (filterByCategory && filterByUser) {
            setFilteredPosts(
                allPosts
                    .filter((post) => post.category === category)
                    .filter((post) => post.authorId === authorId)
            )
        } else if (filterByCategory) {
            setFilteredPosts(
                allPosts.filter((post) => post.category === category)
            )
        } else if (filterByUser) {
            setFilteredPosts(
                allPosts.filter((post) => post.authorId === authorId)
            )
        } else {
            setFilteredPosts(allPosts)
        }

        // console.log(filteredPosts)
        // let tempPosts = [...filteredPosts]
        // console.log(tempPosts)
    }

    useEffect(() => {
        fetchAndFilterPosts()
    }, [allPosts])

    return (
        <>
            {filteredPosts.length > 0 &&
                filteredPosts.map((p) => {
                    return <Post post={p} displayMode="newsfeed" key={p.id} />
                })}
        </>
    )
}
