import { createSlice } from "@reduxjs/toolkit"
import { postUser } from "../../api/usersApiManager"

const initialState = { value: [] }

export const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.value = action.payload
        },
        clearUsers: (state) => {
            state = initialState
        },
        addUser: (state, action) => {
            state.value = [...state.value, action.payload]
            postUser(action.payload)
        }
    }
})

export const { setUsers, clearUsers, addUser } = usersSlice.actions

export default usersSlice.reducer
