import { createSlice } from "@reduxjs/toolkit"
import { postPost } from "../../api/postsApiManager"

const initialState = { value: [] }

export const postsSlice = createSlice({
    name: "posts",
    initialState,
    reducers: {
        setPosts: (state, action) => {
            state.value = action.payload
        },
        clearPosts: (state) => {
            state = initialState
        },
        addPost: (state, action) => {
            state.value = [...state.value, action.payload]
            postPost(action.payload)
        }
    }
})

export const { setPosts, clearPosts, addPost } = postsSlice.actions

export default postsSlice.reducer
