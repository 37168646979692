import styled from "styled-components"

export default function Footer() {
    return (
        <StyledFooter>
            <h3>mjsung.com</h3>
            <p>Made in Korea by MJ Sung </p>
        </StyledFooter>
    )
}

const StyledFooter = styled.footer`
    width: "100%";
    background-color: lightgray;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
