import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { login } from "../auth/authentication"
import { PageWrapDiv } from "../components/shared/LayoutComponents"
import {
    LabeledInput,
    LightBgButton,
    StyledForm
} from "../components/shared/SharedComponents"

export default function Login() {
    const [userId, setUserId] = useState("")
    const [userPassword, setUserPassword] = useState("")

    const navigate = useNavigate()

    const onLoginFormSubmit = async (e) => {
        e.preventDefault()
        const attemptLogin = await login(userId, userPassword)
        alert(attemptLogin)
        navigate("/")
    }

    return (
        <PageWrapDiv>
            <StyledForm onSubmit={onLoginFormSubmit}>
                <LabeledInput
                    labelValue="ID"
                    placeholder="Enter ID"
                    value={userId}
                    onChange={(e) => {
                        setUserId(e.target.value)
                    }}
                />
                <LabeledInput
                    labelValue="Password"
                    type="password"
                    placeholder="Enter password"
                    value={userPassword}
                    onChange={(e) => {
                        setUserPassword(e.target.value)
                    }}
                />
                <LightBgButton
                    onClick={() => {
                        navigate("/register")
                    }}
                >
                    Register
                </LightBgButton>
                <LightBgButton type="submit">Login</LightBgButton>
            </StyledForm>
        </PageWrapDiv>
    )
}
