import { configureStore } from "@reduxjs/toolkit"

import usersReducer from "./slices/usersSlice"
import currentSessionReducer from "./slices/currentSessionSlice"
import postsReducer from "./slices/postsSlice"

export const store = configureStore({
    reducer: {
        users: usersReducer,
        currentSession: currentSessionReducer,
        posts: postsReducer
    }
})
