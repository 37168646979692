import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { DARK_BG_TEXT_COLOR, SECONDARY_COLOR } from "../../style/colors"
import { DarkBgButton, DarkBgTitle, PrimaryButton } from "./SharedComponents"

export default function Header() {
    const navigate = useNavigate()
    const location = useLocation()

    const reloadOrNavigate = (path) => {
        if (path === location.pathname) {
            window.location.reload()
        } else {
            navigate(path)
        }
    }

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const user = useSelector((state) => state.currentSession.value)

    useEffect(() => {
        if (user && user.id !== "") {
            setIsLoggedIn(true)
        }
    }, [user])

    return (
        <StyledHeader>
            <StyledHeaderLeftDiv>
                <StyledHeaderLeftTitleDiv
                    onClick={() => {
                        reloadOrNavigate("/")
                    }}
                >
                    <DarkBgTitle>mjsung.com</DarkBgTitle>
                </StyledHeaderLeftTitleDiv>
            </StyledHeaderLeftDiv>
            <StyledHeaderRightDiv>
                <StyledHeaderRightNavbar>
                    <DarkBgButton
                        onClick={() => {
                            reloadOrNavigate("/")
                        }}
                    >
                        Home
                    </DarkBgButton>
                    {!isLoggedIn ? (
                        <PrimaryButton
                            onClick={() => {
                                reloadOrNavigate("/login")
                            }}
                        >
                            Login
                        </PrimaryButton>
                    ) : (
                        <>
                            <PrimaryButton
                                onClick={() => {
                                    reloadOrNavigate("/writepost")
                                }}
                            >
                                Write
                            </PrimaryButton>
                            <PrimaryButton
                                onClick={() => {
                                    reloadOrNavigate("/logout")
                                }}
                            >
                                Logout
                            </PrimaryButton>
                        </>
                    )}
                </StyledHeaderRightNavbar>
            </StyledHeaderRightDiv>
        </StyledHeader>
    )
}

const StyledHeader = styled.header`
    background-color: ${SECONDARY_COLOR};
    color: ${DARK_BG_TEXT_COLOR};

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;

    height: 9vh;
`

const StyledHeaderLeftDiv = styled.div`
    margin-left: 2%;
    margin-right: auto;
`

const StyledHeaderLeftTitleDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    cursor: pointer;
`

const StyledHeaderRightDiv = styled.div`
    margin-right: 2%;
    margin-left: auto;
`

const StyledHeaderRightNavbar = styled.nav`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;

    & > button {
        margin-left: 1rem;
    }
`
