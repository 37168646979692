import logo from "./logo.svg"
import "./App.css"
import { fetchUsers } from "./api/usersApiManager"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Login from "./pages/Login"
import Header from "./components/shared/Header"
import Footer from "./components/shared/Footer"
import Register from "./pages/Register"
import Home from "./pages/Home"
import LogOut from "./pages/LogOut"
import ViewPost from "./pages/ViewPost"
import WritePost from "./pages/WritePost"
import {
    clearCurrentSession,
    setCurrentSession
} from "./redux/slices/currentSessionSlice"

function App() {
    const dispatch = useDispatch()

    useEffect(() => {
        const session = window.sessionStorage.getItem("currentSession")
        const serverAuthKey = window.sessionStorage.getItem("serverAuthKey")
        if (
            session &&
            serverAuthKey &&
            serverAuthKey === process.env.REACT_APP_SERVER_AUTH_KEY
        ) {
            dispatch(setCurrentSession({ id: session }))
        } else {
            dispatch(clearCurrentSession())
        }
    }, [])

    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="login" element={<Login />} />
                <Route path="logout" element={<LogOut />} />
                <Route path="register" element={<Register />} />
                <Route path="viewpost/:id" element={<ViewPost />} />
                <Route path="writepost" element={<WritePost />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}

export default App
