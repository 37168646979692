import { createSlice } from "@reduxjs/toolkit"

const initialState = { value: { id: "" } }

export const currentSessionSlice = createSlice({
    name: "currentSession",
    initialState,
    reducers: {
        setCurrentSession: (state, action) => {
            state.value = action.payload
            window.sessionStorage.setItem("currentSession", action.payload.id)
            window.sessionStorage.setItem(
                "serverAuthKey",
                process.env.REACT_APP_SERVER_AUTH_KEY
            )
        },
        clearCurrentSession: (state, action) => {
            state = initialState
            window.sessionStorage.removeItem("currentSession")
            window.sessionStorage.removeItem("serverAuthKey")
        }
    }
})

export const { setCurrentSession, clearCurrentSession } =
    currentSessionSlice.actions

export default currentSessionSlice.reducer
