import styled from "styled-components"
import {
    DARK_BG_TEXT_COLOR,
    LIGHT_BG_TEXT_COLOR,
    PRIMARY_COLOR,
    SECONDARY_COLOR
} from "../../style/colors"
import { FONT_SIZE_LARGE, FONT_SIZE_MEDIUM } from "../../style/fontSizes"
import { MOBILE_SCREEN_WIDTH } from "../../style/responsive"

export const DarkBgTitle = styled.h1`
    font-size: 2em;
    color: ${DARK_BG_TEXT_COLOR};
`

export const LightBgTitle = styled.h1`
    font-size: 2em;
    color: ${LIGHT_BG_TEXT_COLOR};
`

const BUTTON_PADDING = "0.5rem 1rem"
const BUTTON_BORDER_RADIUS = "0.4rem"
const BUTTON_TRANSITION = "linear 0.2s"

export const DarkBgButton = styled.button`
    padding: ${BUTTON_PADDING};
    font-size: ${FONT_SIZE_LARGE};
    color: ${DARK_BG_TEXT_COLOR};
    border: 0.1rem solid ${DARK_BG_TEXT_COLOR};
    background-color: transparent;
    border-radius: ${BUTTON_BORDER_RADIUS};
    transition: ${BUTTON_TRANSITION};

    &:hover {
        background-color: ${DARK_BG_TEXT_COLOR};
        color: ${LIGHT_BG_TEXT_COLOR};
    }

    @media (width < ${MOBILE_SCREEN_WIDTH}) {
        font-size: ${FONT_SIZE_MEDIUM};
    }
`

export const LightBgButton = styled.button`
    padding: ${BUTTON_PADDING};
    font-size: ${FONT_SIZE_LARGE};
    color: ${LIGHT_BG_TEXT_COLOR};
    border: 0.1rem solid ${LIGHT_BG_TEXT_COLOR};
    background-color: transparent;
    border-radius: ${BUTTON_BORDER_RADIUS};
    transition: ${BUTTON_TRANSITION};

    &:hover {
        background-color: ${LIGHT_BG_TEXT_COLOR};
        color: ${DARK_BG_TEXT_COLOR};
    }

    @media (width < ${MOBILE_SCREEN_WIDTH}) {
        font-size: ${FONT_SIZE_MEDIUM};
    }
`

export const PrimaryButton = styled.button`
    padding: ${BUTTON_PADDING};
    font-size: ${FONT_SIZE_LARGE};
    color: ${PRIMARY_COLOR};
    border: 0.1rem solid ${PRIMARY_COLOR};
    background-color: transparent;
    border-radius: ${BUTTON_BORDER_RADIUS};
    transition: ${BUTTON_TRANSITION};

    &:hover {
        background-color: ${PRIMARY_COLOR};
        color: ${DARK_BG_TEXT_COLOR};
    }

    @media (width < ${MOBILE_SCREEN_WIDTH}) {
        font-size: ${FONT_SIZE_MEDIUM};
    }
`

export const SecondaryButton = styled.button`
    padding: ${BUTTON_PADDING};
    font-size: ${FONT_SIZE_LARGE};
    color: ${SECONDARY_COLOR};
    border: 0.1rem solid ${SECONDARY_COLOR};
    background-color: transparent;
    border-radius: ${BUTTON_BORDER_RADIUS};
    transition: ${BUTTON_TRANSITION};

    &:hover {
        background-color: ${SECONDARY_COLOR};
        color: ${LIGHT_BG_TEXT_COLOR};
    }

    @media (width < ${MOBILE_SCREEN_WIDTH}) {
        font-size: ${FONT_SIZE_MEDIUM};
    }
`

export const LabeledInput = ({
    labelValue,
    placeholder,
    type = "text",
    value,
    onChange
}) => {
    return (
        <StyledLabeledInput>
            <StyledLabeledInputLabel>{labelValue}</StyledLabeledInputLabel>
            <StyledLabeledInputInput
                placeholder={placeholder}
                value={value}
                type={type}
                onChange={onChange}
            />
        </StyledLabeledInput>
    )
}

export const LabeledTextArea = ({
    labelValue,
    placeholder,
    type = "text",
    value,
    onChange
}) => {
    return (
        <StyledLabeledInput>
            <StyledLabeledInputLabel>{labelValue}</StyledLabeledInputLabel>
            <StyledLabeledInputTextArea
                placeholder={placeholder}
                value={value}
                type={type}
                onChange={onChange}
            />
        </StyledLabeledInput>
    )
}

export const LabeledSelect = ({
    labelValue,
    options = [],
    value,
    onChange
}) => {
    return (
        <StyledLabeledInput>
            <StyledLabeledInputLabel>{labelValue}</StyledLabeledInputLabel>
            <StyledLabeledInputSelect value={value} onChange={onChange}>
                {options.map((item) => {
                    return (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    )
                })}
            </StyledLabeledInputSelect>
        </StyledLabeledInput>
    )
}

const StyledLabeledInput = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
`

const StyledLabeledInputLabel = styled.label`
    font-size: ${FONT_SIZE_MEDIUM};
    margin-right: auto;
`

const StyledLabeledInputInput = styled.input`
    font-size: ${FONT_SIZE_MEDIUM};
    margin-left: auto;
`

const StyledLabeledInputTextArea = styled.textarea`
    font-size: ${FONT_SIZE_MEDIUM};
    margin-left: auto;
`

const StyledLabeledInputSelect = styled.select`
    font-size: ${FONT_SIZE_MEDIUM};
    margin-left: auto;
`

export const StyledForm = styled.form`
    border: 0.1rem solid ${SECONDARY_COLOR};
    width: 70%;
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
