import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { register } from "../auth/authentication"
import { PageWrapDiv } from "../components/shared/LayoutComponents"
import {
    LabeledInput,
    LightBgButton,
    StyledForm
} from "../components/shared/SharedComponents"

export default function Register() {
    const [userId, setUserId] = useState("")
    const [userPassword, setUserPassword] = useState("")

    const navigate = useNavigate()

    const onRegisterFormSubmit = async (e) => {
        e.preventDefault()
        alert(await register(userId, userPassword))
        navigate("/login")
    }

    return (
        <PageWrapDiv>
            <StyledForm onSubmit={onRegisterFormSubmit}>
                <LabeledInput
                    labelValue="ID"
                    placeholder="Enter ID"
                    value={userId}
                    onChange={(e) => {
                        setUserId(e.target.value)
                    }}
                />
                <LabeledInput
                    labelValue="Password"
                    type="password"
                    placeholder="Enter password"
                    value={userPassword}
                    onChange={(e) => {
                        setUserPassword(e.target.value)
                    }}
                />
                <LightBgButton type="submit">Register</LightBgButton>
            </StyledForm>
        </PageWrapDiv>
    )
}
