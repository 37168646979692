import {
    MOBILE_SCREEN_WIDTH,
    MOBILE_SCREEN_WIDTH_90_PERCENT
} from "../../style/responsive"

const { default: styled } = require("styled-components")

export const PageWrapDiv = styled.div`
    min-height: 91vh;
`

export const PageWrapDivInner = styled.div`
    width: 70%;
    min-width: ${MOBILE_SCREEN_WIDTH_90_PERCENT};

    margin: auto;

    @media (width < ${MOBILE_SCREEN_WIDTH}) {
        min-width: 90%;
    }
`
